import { graphql, useStaticQuery } from 'gatsby';

import { remapServerData } from '@shared/servers-helpers';

const serversQuery = graphql`
  query {
    prefetchedServerData {
      servers {
        continents {
          locations
          name
        }
        groups {
          code
          id
          name
          servers
        }
        locations {
          city_name
          continent
          coordinates {
            latitude
            longitude
          }
          country_name
          id
          iso_code
          name
        }
        servers {
          id
          location_id
        }
      }
    }
  }
`;

export const useServersQuery = () => {
  const serverData = [];

  return remapServerData(serverData);
};
