import { LOADING_OPTIONS } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
import AppStore from '@images/app-store-apple.svg';
// Perks images
import ZeroLogs from '@images/product-page/zero-logs.svg';
import ProtectionExpert from '@images/product-page/protection-expert.svg';
import ExtraLayer from '@images/product-page/extra-layer.svg';
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForMac:metaData.title'),
    description: t('vpnForMac:metaData.description'),
    image: META_IMAGE,
    keywords: ['VPN for macOS', 'free VPN for Mac', 'VPN on Mac', 'best VPN for Mac'],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForMac:headerCta.title'),
      subtitle: t('vpnForMac:headerCta.subtitle'),
      description: [
        { title: t('vpnForMac:headerCta.productFeatures.unlimitedBandwidth') },
        { title: t('vpnForMac:headerCta.productFeatures.supportWireguard') },
        { title: t('vpnForMac:headerCta.productFeatures.highSpeed') },
        { title: t('vpnForMac:headerCta.productFeatures.protection') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.mac,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.macOS },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/mac-header-image-mobile.png'),
        alt: 'mac page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/mac-header-image-tablet.png'),
        alt: 'mac page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/mac-header-image.png'),
        alt: 'mac page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2350px',
        position: {
          top: -80,
          height: 715,
          maxWidth: 2350,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForMac:stepCards.title'),
    description: t('vpnForMac:stepCards.description'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForMac:stepCards.downloadInstall.title'),
        description: t('vpnForMac:stepCards.downloadInstall.description'),
        button: {
          image: { src: AppStore, alt: 'Get it on App Store' },
          handleOnClick: () => {
            window.open(APPS_LINKS.mac, '_blank').focus();
            trackCustomGA4Event({ eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.macOS });
          },
        },
      },
      {
        image: ConnectEnjoy,
        step: 2,
        title: t('vpnForMac:stepCards.connectEnjoy.title'),
        description: t('vpnForMac:stepCards.connectEnjoy.description'),
      },
      {
        image: UpgradeAnytime,
        step: 3,
        title: t('vpnForMac:stepCards.upgradeAnytime.title'),
        description: t('vpnForMac:stepCards.upgradeAnytime.description', {
          serverCount: SERVER_COUNT,
        }),
      },
    ],
  }),
  digitalLife: (t) => ({
    title: t('vpnForMac:reclaimControl'),
    description: t('vpnForMac:joinUs'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForMac:contentBlocks.safety.title'),
        description: t('vpnForMac:contentBlocks.safety.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: ZeroLogs,
        title: t('vpnForMac:perkCards.zeroLogs.title'),
        description: t('vpnForMac:perkCards.zeroLogs.description'),
      },
      {
        image: ProtectionExpert,
        title: t('vpnForMac:perkCards.protectionExpert.title'),
        description: t('vpnForMac:perkCards.protectionExpert.description'),
      },
    ],
  }),
  highSpeed: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/lightning-speed.png'),
        title: t('vpnForMac:contentBlocks.speed.title'),
        description: t('vpnForMac:contentBlocks.speed.description'),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    items: [
      {
        image: ExtraLayer,
        title: t('vpnForMac:perkCards.extraSecurity.title'),
        description: t('vpnForMac:perkCards.extraSecurity.description'),
      },
      {
        image: UnlimitedAccess,
        title: t('vpnForMac:perkCards.unlimitedUse.title'),
        description: t('vpnForMac:perkCards.unlimitedUse.description'),
      },
    ],
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
  }),
  reviews: (t) => ({
    title: t('vpnForMac:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForMac:pricingSection.title'),
    description: t('vpnForMac:pricingSection.description'),
    footerProps: {
      text: t('vpnForMac:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.mac,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.macOS },
        },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Can you use Atlas VPN for free?',
        content:
          'Yes, you can use the free Atlas VPN version. It features multiple free servers that you can connect to without paying a cent. You can upgrade to premium at any point to increase the number of available servers, enjoy additional features, and boost performance.',
      },
      {
        header: 'Why should you upgrade to premium?',
        listLabel: 'Premium services bring:',
        listItems: [
          'Video-optimized servers',
          `More than ${SERVER_COUNT} servers in ${locationCount} locations worldwide`,
          '24/7 customer support',
          'Simultaneous premium services usage on an unlimited number of devices',
          'Privacy Pro servers for more security',
          'Access to Android TV and Amazon Fire TV apps',
          'No data limit and better speed',
        ],
      },
      {
        header: 'Is a VPN for Mac necessary?',
        content:
          'Yes, it is best if you protect your Mac device with a VPN. The truth is, your data and digital identity can be compromised, and you must combat these threats before it is too late. Your information could leak due to unprotected networks, and various entities (such as your ISP) can log and sell data about your digital activities. A VPN helps you prevent such unfortunate scenarios and avoid practices that continuously invade your privacy. Additionally, you get to reclaim open internet access, meaning that content will become available despite blocks or regional restrictions.',
      },
      {
        header: 'Should you keep a VPN active around the clock?',
        content:
          'Yes, we strongly recommend that you keep a VPN on during all your activities. It will guarantee that no information about your online activities or location reaches external parties. We also encourage you to enable our Kill Switch feature, preventing accidental data leaks. A Kill Switch temporarily blocks access to the internet when your VPN connection drops unexpectedly.',
      },
      {
        header: 'How can you sign in to your premium account and add new devices?',
        withBullets: false,
        listLabel:
          'When purchasing Atlas VPN premium services, you will provide us with an email address. To activate and sign in to your premium account, follow these steps:',
        listItems: [
          '1. Download and install your preferred app (iOS, Android, macOS, Windows, Android TV, or Amazon Fire TV).',
          '2. Find the “Sign in” option.',
          '3. Provide the email address you used to purchase your premium plan.',
          '4. Follow further instructions in the app. You will receive a confirmation code in your inbox.',
        ],
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForMac:footerCta.title'),
      description: t('vpnForMac:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'mac page footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'mac page footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'mac page footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'mac page footer',
      },
      desktop: {
        src: footerImage,
        alt: 'mac page footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
